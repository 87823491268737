<template>
  <div class="footnote">
    * {{ $t('Required fileds') }}
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
