<template>
  <b-modal
    id="switch-to-mentor-profile-modal"
    ref="switch-to-mentor-profile-modal"
    centered
    size="md"
    :ok-title="$t('Switch to mentor profile')"
    ok-only
    @ok="switchAccount('mentor')"
  >
    <template #modal-title>
      <span class="font-weight-bolder">{{ $t('Switch to mentor profile') }}</span>
    </template>
    <b-row>
      <b-col>
        <b-card-text class="py-1">
          {{ $t('Switch to mentor profile') }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BButton, BCardText, BCol, BModal, BRow } from "bootstrap-vue";
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BModal,
    BRow
  },
  data() {
    return {};
  },
  methods: {
    show() {
      this.$refs["switch-to-mentor-profile-modal"].show();
    },
    async switchAccount(accountType) {
      const accountSwitched = await this.$store.dispatch('user/switchAccount', accountType)
      if(accountSwitched) {
        this.$root.$bvToast.toast(this.$t('Switched to mentor profile'), {
          title: this.$t('Confirmation'),
          variant: 'success',
          solid: true,
        })
        this.$store.dispatch('mentee/clear')
        this.$refs["switch-to-mentor-profile-modal"].hide();
        const route = getHomeRouteForLoggedInUser(this.$store.getters['user/getUserRole'])
        if (this.$route.name !== route.name) {
          this.$router.push(route)
        }
      }
      else {
        this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        })
        await this.$store.dispatch('user/logout')
        if (this.$route.name !== 'home') {
          this.$router.push({ name: 'home' })
        }
        return
      }
    }
  },
};
</script>

<style>

</style>
