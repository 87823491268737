<template>
  <div>
    <validation-observer ref="signInDetailsObserver">
      <b-row>
        <b-col>
          <b-form-group>
            <label for="i-sign-in-email">
              {{ $t('SignInEmail') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="signInEmail"
              rules="required|email|min:3|max:255"
              mode="lazy"
            >
              <b-form-input
                id="i-sign-in-email"
                v-model="signInEmail"
                disabled
                type="email"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="i-password">
              {{ $t('Password') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required|password"
              mode="lazy"
            >
              <b-input-group>
                <b-form-input
                  id="i-password"
                  v-model="password"
                  :type="passwordFieldType"
                  size="lg"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <label>
              {{ $t('MentorRegistrationReferral') }}
          </label>
          <b-form-group >
            <v-select
              v-model="referrals"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :placeholder="$t('Choose')"
              label="text"
              :options="userReferralOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group v-if="referralFreeTextVisible">
            <label for="i-referral-free-text">
              {{ $t('MentorRegistrationReferralFreeText') }}
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="referralFreeText"
              rules="min:3|max:200"
              mode="lazy"
            >
              <b-form-input
                id="i-referral-free-text"
                v-model="referralFreeText"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <b-form-checkbox
            v-model="giveConsent"
            value="1"
          >
            {{ $t('MentorGiveConsent') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="acceptTermsAndConditions"
            :rules="{
              required: {
                allowFalse: false
              }
            }"
            mode="lazy"
          >
            <b-form-checkbox
              v-model="acceptTermsAndConditions"
              value="1"
            >
              <div>
                {{ $t('I agree to') }}
                <b-link :to="{ name: 'mentoring-terms' }" target="_blank">{{ $t('the terms and conditions of WoT') }}</b-link>
                {{ $t('and') }}
                <b-link :to="{ name: 'privacy-policy' }" target="_blank">{{ $t('the privacy policy') }}</b-link>
                <template v-if="showMentorFields">
                  {{ $t('as a mentor') }}
                </template>
                <template v-if="showMenteeFields">
                  {{ $t('as a mentee') }}
                </template>
                <required-field />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink
} from 'bootstrap-vue'
import { required, password, email, min, max } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import { useUserReferralRepository } from '@mentoring-platform/composables'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    RequiredField,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  mixins: [
    togglePasswordVisibility
  ],
  props: {
    showMentorFields: {
      type: Boolean,
      default: false
    },
    showMenteeFields: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      signInEmail: null,
      password: null,
      acceptTermsAndConditions: false,
      giveConsent: false,
      referrals: [],
      referralFreeText: null
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    referralFreeTextVisible() {
      const visible = this.referrals.find(selectedReferral => selectedReferral.value === 11) // 'other'
      if(!visible) {
        this.referralFreeText = null
      }
      return visible
    }
  },
  setup() {
    const { getUserReferrals, userReferralOptions } = useUserReferralRepository()
    getUserReferrals()

    return {
      userReferralOptions
    }
  },
  created() {
    if(this.user) {
      this.signInEmail = this.user.email
    }
  },
  beforeMount() {
    // there are not same!!!
    this.$bus.$on('mentor-registration-first-step-email-filled', this.setEmail)
    this.$bus.$on('mentee-registration-first-step-email-filled', this.setEmail)
  },
  beforeDestroy() {
    // there are not same!!!
    this.$bus.$off('mentor-registration-first-step-email-filled', this.setEmail)
    this.$bus.$off('mentee-registration-first-step-email-filled', this.setEmail)
  },
  methods: {
    isValid() {
      return this.$refs.signInDetailsObserver.validate().then(success => success)
    },
    collectData() {
      return {
        password: this.password,
        password_confirmation: this.password,
        accept_terms_and_conditions: this.acceptTermsAndConditions,
        give_consent: this.giveConsent,
        referrals: this.referrals.map(selectedReferral => selectedReferral.value),
        referral_free_text: this.referralFreeText
      }
    },
    setEmail(email) {
      this.signInEmail = email
    },
  },
}
</script>

<style lang="scss" scoped>
.v-select input {
  height: 2.7rem;
}
</style>
