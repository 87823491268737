var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"professionalDetailsObserver"},[(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-work-status"}},[_vm._v(" "+_vm._s(_vm.$t('Work status'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"workStatus","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-work-status","options":_vm.workStatusOptions,"size":"lg"},model:{value:(_vm.workStatus),callback:function ($$v) {_vm.workStatus=$$v},expression:"workStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2385736336)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.workStatusFreeTextEnabled)?_c('b-form-group',[_c('label',{attrs:{"for":"i-work-status-free-text"}},[_vm._v(" "+_vm._s(_vm.$t('Tell us more'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"workStatusFreeText","rules":"required|min:3|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-work-status-free-text","size":"lg"},model:{value:(_vm.workStatusFreeText),callback:function ($$v) {_vm.workStatusFreeText=$$v},expression:"workStatusFreeText"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1554167469)})],1):_vm._e()],1)],1):_vm._e(),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-industry"}},[_vm._v(" "+_vm._s(_vm.$t('Industry'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"industry","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","placeholder":_vm.$t('Choose'),"label":"text","options":_vm.translatedIndustryOptions},model:{value:(_vm.translatedIndustries),callback:function ($$v) {_vm.translatedIndustries=$$v},expression:"translatedIndustries"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2464492449)})],1)],1),(_vm.industryFreeTextEnabled)?_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-industry-free-text"}},[_vm._v(" "+_vm._s(_vm.$t('Tell us more'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"industryFreeText","rules":"min:3|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-industry-free-text","size":"lg"},model:{value:(_vm.industryFreeText),callback:function ($$v) {_vm.industryFreeText=$$v},expression:"industryFreeText"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2691254336)})],1)],1):_vm._e()],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-professional-title"}},[_vm._v(" "+_vm._s(_vm.$t('ProfessionalTitle'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.professionalTitle[locale],"rules":"required|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-professional-title' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1 || _vm.forceFlags},model:{value:(_vm.professionalTitle[locale]),callback:function ($$v) {_vm.$set(_vm.professionalTitle, locale, $$v)},expression:"professionalTitle[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1),(_vm.showMentorFields && !_vm.showOnlyMultilanguageFields)?_c('b-col',{attrs:{"md":"6"}}):_vm._e(),(!_vm.showOnlyMultilanguageFields)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":!_vm.showMentorFields ? _vm.$t('If you do not have working experience, leave the field empty') : ''}},[_c('label',{attrs:{"for":"i-working-experience"}},[_vm._v(" "+_vm._s(_vm.$t('ProfessionalExperience'))+" "),(_vm.showMentorFields)?_c('required-field'):_vm._e()],1),_c('validation-provider',{attrs:{"name":"professionalExperience","rules":'date_format:MM-yyyy|working_experience' + (_vm.showMentorFields ? '|required' : ''),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control cleave-datepicker",attrs:{"id":"date","raw":false,"options":_vm.cleaveOptions.date,"placeholder":_vm.$t('MM-YYYY')},model:{value:(_vm.professionalExperience),callback:function ($$v) {_vm.professionalExperience=$$v},expression:"professionalExperience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2687691278)})],1)],1):_vm._e(),(_vm.showMentorFields && !_vm.showOnlyMultilanguageFields)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('The answers above may or may not be identical')}},[_c('label',{attrs:{"for":"i-mentoring-experience"}},[_vm._v(" "+_vm._s(_vm.$t('MentoringExperience'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"mentoringExperience","rules":"required|date_format:MM-yyyy|working_experience","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control cleave-datepicker",attrs:{"id":"date","raw":false,"options":_vm.cleaveOptions.date,"placeholder":_vm.$t('MM-YYYY')},model:{value:(_vm.mentoringExperience),callback:function ($$v) {_vm.mentoringExperience=$$v},expression:"mentoringExperience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3632114410)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }