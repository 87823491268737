<template>
  <div>
    <validation-observer ref="professionalDetailsObserver">
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col  md="6">
          <b-form-group>
            <label for="i-work-status">
              {{ $t('Work status') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="workStatus"
              rules="required"
              mode="lazy"
            >
              <b-form-select
                id="i-work-status"
                v-model="workStatus"
                :options="workStatusOptions"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group v-if="workStatusFreeTextEnabled">
            <label for="i-work-status-free-text">
              {{ $t('Tell us more') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="workStatusFreeText"
              rules="required|min:3|max:200"
              mode="lazy"
            >
              <b-form-input
                id="i-work-status-free-text"
                v-model="workStatusFreeText"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col>
          <b-form-group>
            <label for="i-industry">
              {{ $t('Industry') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="industry"
              rules="required"
              mode="lazy"
            >
              <v-select
                v-model="translatedIndustries"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :placeholder="$t('Choose')"
                label="text"
                :options="translatedIndustryOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="industryFreeTextEnabled">
          <b-form-group>
            <label for="i-industry-free-text">
              {{ $t('Tell us more') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="industryFreeText"
              rules="min:3|max:200"
              mode="lazy"
            >
              <b-form-input
                id="i-industry-free-text"
                v-model="industryFreeText"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <label for="i-professional-title">
              {{ $t('ProfessionalTitle') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="professionalTitle[locale]"
                rules="required|max:200"
                mode="lazy"
              >
                <localized-input
                  :id="'i-professional-title' + index"
                  v-model="professionalTitle[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1 || forceFlags"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="showMentorFields && !showOnlyMultilanguageFields">
          <!-- empty column for spacing -->
        </b-col>
        <b-col md="6" v-if="!showOnlyMultilanguageFields">
          <b-form-group :description="!showMentorFields ? $t('If you do not have working experience, leave the field empty') : ''">
            <label for="i-working-experience">
              {{ $t('ProfessionalExperience') }} <required-field v-if="showMentorFields" />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="professionalExperience"
              :rules="'date_format:MM-yyyy|working_experience' + (showMentorFields ? '|required' : '')"
              mode="lazy"
            >
              <cleave
                id="date"
                v-model="professionalExperience"
                class="form-control cleave-datepicker"
                :raw="false"
                :options="cleaveOptions.date"
                :placeholder="$t('MM-YYYY')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="showMentorFields && !showOnlyMultilanguageFields">
          <b-form-group :description="$t('The answers above may or may not be identical')">
            <label for="i-mentoring-experience">
              {{ $t('MentoringExperience') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="mentoringExperience"
              rules="required|date_format:MM-yyyy|working_experience"
              mode="lazy"
            >
              <cleave
                id="date"
                v-model="mentoringExperience"
                class="form-control cleave-datepicker"
                :raw="false"
                :options="cleaveOptions.date"
                :placeholder="$t('MM-YYYY')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect
} from 'bootstrap-vue'
import { required, min, max, date_format, working_experience } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import { useWorkStatusRepository, useIndustryRepository } from '@mentoring-platform/composables'
import vSelect from 'vue-select'
import LocalizedInput from '@mentoring-platform/views/components/blocks/LocalizedInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Cleave,
    LocalizedInput,
    RequiredField,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  props: {
    showMentorFields: {
      type: Boolean,
      default: false
    },
    showMenteeFields: {
      type: Boolean,
      default: false
    },
    showOnlyMultilanguageFields: {
      type: Boolean,
      default: false
    },
    forceFlags: {
      type: Boolean,
      default: false
    },
    mentor: {
      type: Object
    },
    mentee: {
      type: Object
    },
    initialLocales: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      workStatus: null,
      workStatusFreeText: null,
      industries: [],
      industryFreeText: null,
      professionalTitle: {
        gr: null,
        en: null
      },
      professionalExperience: null,
      mentoringExperience: null,
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['m', 'Y']
        },
      },
      selectedLocales: []
    }
  },
  computed: {
    workStatusFreeTextEnabled() {
      const visible = this.workStatus === 'other'
      if(!visible) {
        this.workStatusFreeText = null
      }
      return visible;
    },
    industryFreeTextEnabled() {
      const visible = this.industries.find(selectedIndustry => selectedIndustry.value === this.OTHER_INDUSTRY_ID) // 'other'
      if(!visible) {
        this.industryFreeText = null
      }
      return visible;
    },
    translatedIndustries: {
      get() {
        const translatedIndustries = []
        this.industries.forEach(
          industryData => translatedIndustries.push({
            value: industryData.value,
            text: this.$t(industryData.text)
          })
        )
        return translatedIndustries
      },
      set(newValue) {
        this.industries = this.industryOptions.filter(option => newValue.filter(v => v.value === option.value).length > 0)
      }
    },
    translatedIndustryOptions() {
      const translatedIndustryOptions = []
      this.industryOptions.forEach(
        industryData => translatedIndustryOptions.push({
          value: industryData.value,
          text: this.$t(industryData.text)
        })
      )
      return translatedIndustryOptions
    }
  },
  setup() {
    const { getWorkStatuses, workStatusOptions } = useWorkStatusRepository()
    const { getIndustries, industryOptions, OTHER_INDUSTRY_ID } = useIndustryRepository()
    getIndustries()
    getWorkStatuses()

    return {
      workStatusOptions,
      industryOptions,
      OTHER_INDUSTRY_ID
    }
  },
  created() {
    let user = null
    if (this.mentor) {
      this.setLocales(this.mentor.supported_languages)
      this.professionalTitle = this.mentor.translatable_fields.professional_title
      this.mentoringExperience = this.mentor.mentoring_experience
      user = this.mentor.user
    } else if (this.mentee) {
      this.setLocales(['gr'])
      this.professionalTitle.gr = this.mentee.user.professional_title
      user = this.mentee.user
    }

    if (user) {
      this.workStatus = user.work_status_id
      if (user.custom_work_status != null) {
        this.workStatus = 'other'
        this.workStatusFreeText = user.custom_work_status
      }
      user.industries.forEach(
        industry => {
          if (industry.id === this.OTHER_INDUSTRY_ID) {
            this.industryFreeText = industry.pivot.description
          }
          this.industries.push({ value: industry.id, text: industry.name })
        }
      )
      this.professionalExperience = user.professional_experience
    } else if (!this.showMentorFields) { // mentee registration
      this.setLocales(['gr'])
    } else if (this.initialLocales.length) {
      this.setLocales(this.initialLocales)
    }
  },
  beforeMount() {
    this.$bus.$on('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$on('mentor-locales-changed', this.setLocales)
  },
  beforeDestroy() {
    this.$bus.$off('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$off('mentor-locales-changed', this.setLocales)
  },
  methods: {
    isValid() {
      return this.$refs.professionalDetailsObserver.validate().then(success => success)
    },
    collectData() {
      let professionalTitle = {}
      if (this.showMentorFields) {
        this.selectedLocales.forEach(language => {
          professionalTitle[language] = this.professionalTitle[language]
        })
      } else {
        professionalTitle = { gr: this.professionalTitle.gr }
      }

      return {
        work_status_id: this.workStatus === null || this.workStatus === 'other' ? null : this.workStatus,
        work_status_free_text: this.workStatusFreeText,
        industries: this.industries.map(selectedIndustry => selectedIndustry.value),
        industry_free_text: this.industryFreeText,
        professional_title: professionalTitle,
        professional_experience: this.professionalExperience,
        mentoring_experience: this.mentoringExperience,
      }
    },
    setLocales(locales) {
      this.selectedLocales = locales
    }
  },
}
</script>

<style lang="scss" scoped>
.v-select input {
  height: 2.7rem;
}
</style>
