<template>
  <div>
    <b-modal
      id="email-already-exists-modal"
      ref="email-already-exists-modal"
      centered
      size="lg"
      :ok-title="$t('SignIn')"
      ok-only
      header-class="modal-header-class"
      footer-class="modal-footer-class"
      @ok="$router.push({name: 'auth-login'})"
    >
      <b-row>
        <b-col class="px-2">
          <h2 class="mb-2">{{ $t('This email is already used') }}</h2>
          <b-card-text>
            {{ $t('This email is already used - explanation - part 1') }}
          </b-card-text>
          <b-card-text>
            {{ $t('This email is already used - explanation - part 2') }}
          </b-card-text>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  BButton,
  BRow,
  BCardText,
  BCol,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BRow,
    BCardText,
    BCol,
    BModal,
  },
  data() {
    return {

    }
  },
  methods: {
    show() {
      this.$refs['email-already-exists-modal'].show()
    }
  }
}
</script>

<style lang="scss">
#email-already-exists-modal {
  .modal-header-class{
    background: white !important;
    border: none;
  }

  .modal-footer-class {
    display: flex;
    justify-content: flex-start;
    border: none;
    padding-bottom: 30px;
  }
}
</style>
