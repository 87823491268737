<template>
  <div>
    <b-row>
      <b-col
        md="8"
        xs="12"
      >
        <b-card class="p-2">
          <b-card-title class="font-weight-bolder">
            {{ $t('What we offer to a mentor?') }}
          </b-card-title>
          <!-- eslint-disable-next-line -->
          <div v-html="$t('What we offer to a mentor')" />
          <b-card-title class="font-weight-bolder">
            {{ $t('What we ask for from a mentor?') }}
          </b-card-title>
          <!-- eslint-disable-next-line -->
          <div v-html="$t('What we ask for from a mentor')" />
          <p>
            <b-link :to="{ name: 'pages-knowledge-base-category', params: { category: 'for-mentors' } }">
              {{ $t('Find more information here.') }}
            </b-link>
          </p>
        </b-card>
      </b-col>
      <b-col
        md="4"
        xs="12"
      >
        <b-card
          no-body
          :img-src="require('@/assets/images/pages/Become_a_Mentor_F.jpg')"
          img-alt="Card image cactus"
          img-top
        >
          <b-card-body>
            <b-card-title>
              {{ $t('Do you want to become a mentor?') }}
            </b-card-title>
            <b-card-text>
              {{ $t('We want you with us!') }}
            </b-card-text>
            <b-button
              variant="primary"
              @click="showForm"
            >
              {{ $t('Submit application') }} &gt;
            </b-button>
          </b-card-body>
        </b-card>
        <b-card v-if="isGreekLocale">
          <b-card-title>
            Mentors' Toolbox
          </b-card-title>
          <b-card-text>
            Θέλεις να γίνεις ακόμα καλύτερη μέντορας και να αποκτήσεις περισσότερα εφόδια και για τη δική σου επαγγελματική ζωή;
          </b-card-text>
          <b-button
            variant="primary"
            href="https://womenontop.gr/mentors-toolbox/"
            target="_blank"
          >
            Άνοιξε την εργαλειοθήκη &gt;
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BRow,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCol,
    BRow,
    BLink,
  },
  data() {
    return {

    }
  },
  computed: {
    isGreekLocale() {
      return this.$i18n.locale === 'gr'
    }
  },
  methods: {
    showForm() {
      this.$emit('show-form')
    }
  }
}
</script>

<style scoped>

</style>
