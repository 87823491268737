<template>
  <b-modal
    id="image-cropper-modal"
    ref="image-cropper-modal"
    centered
    size="lg"
    :title="$t('Edit image')"
    title-class="font-weight-bolder"
    ok-only
    @ok="confirmChoice"
    @hide="onHide"
    @close="onClose"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="image-containaer">
      <b-img
        class="image-preview"
        ref="image-preview"
        :src="photoAsBase64"
        @load="initCropper"
      />
    </div>
  </b-modal>
</template>

<script>
import {
  BImg,
  BModal
} from 'bootstrap-vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BImg,
    BModal,
    Cropper
  },
  data() {
    return {
      photoAsBase64: null,
      reader: null,
      cropper: null
    }
  },
  created() {
    this.reader = new FileReader()
    this.reader.onloadend = () => {
      this.photoAsBase64 = this.reader.result
    }
  },
  methods: {
    show(newImageFile) {
      this.reader.readAsDataURL(newImageFile)
      this.$refs['image-cropper-modal'].show()
    },
    onHide() {
      this.cropper.destroy()
      this.photoAsBase64 = null
    },
    onClose() {
      this.$bus.$emit('image-not-cropped')
    },
    initCropper() {
      this.cropper = new Cropper(this.$refs['image-preview'], {
        aspectRatio: 1,
        viewMode: 1,
        dragMode: 'move',
        toggleDragModeOnDblclick: false,
        minCropBoxWidth: 300,
        minCropBoxHeight: 300,
        movable: false
      })
    },
    confirmChoice() {
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        const newFile = new File([blob], 'image.jpeg', {
          type: blob.type,
        })
        this.$bus.$emit('image-cropped', newFile)
      }, 'image/jpeg', 0.6)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-containaer {

  .image-preview {
    display: block;
    max-width: 100%;
  }

}
</style>
