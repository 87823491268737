var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"personalDetailsObserver"},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-name"}},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.name[locale],"rules":"required|min:2|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-name' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1 || _vm.forceFlags},model:{value:(_vm.name[locale]),callback:function ($$v) {_vm.$set(_vm.name, locale, $$v)},expression:"name[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1),_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-surname"}},[_vm._v(" "+_vm._s(_vm.$t('Surname'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.surname[locale],"rules":"required|min:3|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-surname' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1 || _vm.forceFlags},model:{value:(_vm.surname[locale]),callback:function ($$v) {_vm.$set(_vm.surname, locale, $$v)},expression:"surname[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1)],1),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('This email will be used for sign in')}},[_c('label',{attrs:{"for":"i-email"}},[_vm._v(" "+_vm._s(_vm.$t('ContactEmail'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"email","rules":"required|email|min:3|max:255","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-email","type":"email","size":"lg"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,564919650)})],1)],1),_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-phone"}},[_vm._v(" "+_vm._s(_vm.$t('Phone'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"phone","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-phone","size":"lg"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1012336640)})],1)],1)],1):_vm._e(),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-country"}},[_vm._v(" "+_vm._s(_vm.$t('Country'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"country","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-country","options":_vm.countryOptions,"size":"lg"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1942750496)})],1)],1),_c('b-col',{attrs:{"xs":"12","sm":"6"}},[(_vm.countyVisible)?_c('b-form-group',[_c('label',{attrs:{"for":"i-county"}},[_vm._v(" "+_vm._s(_vm.$t('County'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"county","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"i-county","options":_vm.countyOptions,"size":"lg"},model:{value:(_vm.county),callback:function ($$v) {_vm.county=$$v},expression:"county"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2287849074)})],1):_vm._e()],1)],1):_vm._e(),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-birthdate"}},[_vm._v(" "+_vm._s(_vm.$t('Birthdate'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"birthdate","rules":"required|date_format:dd-MM-yyyy","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control cleave-datepicker",attrs:{"id":"date","raw":false,"options":_vm.cleaveOptions.date,"placeholder":_vm.$t('DD-MM-YYYY')},model:{value:(_vm.birthdate),callback:function ($$v) {_vm.birthdate=$$v},expression:"birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,373265063)})],1)],1)],1):_vm._e()],1),_c('email-already-exists-modal',{ref:"email-already-exists-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }