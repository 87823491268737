<template>
  <div>
    <b-row>
      <b-col>
        <validation-observer ref="currentlySelectedLocalesObserver">
          <b-form-group
            :label="$t('Please select in which languages you can support mentoring')"
            :description="description"
          >
            <validation-provider
              v-slot="{ errors }"
              name="currentlySelectedLocales"
              rules="required"
              mode="passive"
            >
              <b-form-checkbox-group
                v-model="currentlySelectedLocales"
                stacked
              >
                <b-form-checkbox
                  v-for="(localeInfo, index) in locales"
                  :key="index"
                  :value="localeInfo.locale"
                  size="lg"
                  :disabled="proccessingInProgress"
                >
                  {{ localeInfo.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-col>
    </b-row>
    <fill-missing-mentor-fields-modal
      ref="fill-missing-mentor-fields-modal"
      @adding-new-locale-cancelled="addingNewLocaleCancelled"
    />
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  // BImg,
  BRow,
  BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line
import { required } from '@mentoring-platform-validations'
import { useLocales } from '@mentoring-platform/composables'
import FillMissingMentorFieldsModal from '@mentoring-platform/views/components/blocks/FillMissingMentorFieldsModal.vue'

export default {
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    // BImg,
    BRow,
    BCol,
    FillMissingMentorFieldsModal,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mentor: {
      type: Object,
      required: false,
      default: null
    },
    emitEvent: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initiallySelectedLocales: [],
      previouslySelectedLocales: [],
      currentlySelectedLocales: [],
      proccessingInProgress: false
    }
  },
  computed: {
    description() {
      if (!this.mentor) { // registration
        return this.$t('In the next steps, you\'ll be prompted to input profile fields in the languages you have selected.')
      }
      return null
    }
  },
  watch: {
    currentlySelectedLocales(newValue) {
      this.processLocales(newValue)
    }
  },
  created() {
    if (!this.mentor) {
      this.currentlySelectedLocales.push(this.$i18n.locale)
    } else {
      this.initiallySelectedLocales = this.mentor.supported_languages
      this.previouslySelectedLocales = this.initiallySelectedLocales
      this.currentlySelectedLocales = this.initiallySelectedLocales
    }
  },
  methods: {
    isValid() {
      return this.$refs.currentlySelectedLocalesObserver.validate().then(success => success)
    },
    collectData() {
      if (this.mentor) { // edit existing mentor
        const name = {}
        const surname = {}
        const professionalTitle = {}
        const mentorDescription = {}
        this.currentlySelectedLocales.forEach(language => {
          name[language] = this.mentor.translatable_fields.name[language]
          surname[language] = this.mentor.translatable_fields.surname[language]
          professionalTitle[language] = this.mentor.translatable_fields.professional_title[language]
          mentorDescription[language] = this.mentor.translatable_fields.description[language]
        })
        return {
          selected_languages: this.currentlySelectedLocales,
          name,
          surname,
          professional_title: professionalTitle,
          mentor_description: mentorDescription
        }
      }
      // registration
      return {
        selected_languages: this.currentlySelectedLocales
      }
    },
    startProcessing() {
      this.proccessingInProgress = true
    },
    endProcessing() {
      this.proccessingInProgress = false
    },
    async processLocales(currentlySelectedLocales) {
      this.startProcessing()
      const isValid = await this.isValid()
      if (!isValid) {
        this.endProcessing()
        return
      }
      if (currentlySelectedLocales !== this.previouslySelectedLocales) {
        if (this.emitEvent) {
          this.$bus.$emit('mentor-locales-changed', currentlySelectedLocales)
        } else if (this.showModal) {
          const newLocales = currentlySelectedLocales.filter(locale => !this.initiallySelectedLocales.includes(locale))
          if (newLocales.length) {
            this.$refs['fill-missing-mentor-fields-modal'].show(this.mentor, newLocales[0])
          }
        }
        this.previouslySelectedLocales = currentlySelectedLocales
      }
      this.endProcessing()
    },
    addingNewLocaleCancelled(newLocale) {
      const index = this.currentlySelectedLocales.indexOf(newLocale)
      this.currentlySelectedLocales.splice(index, 1)
    }
  },
  setup() {
    const { locales } = useLocales()

    return {
      locales,
    }
  }
}
</script>
