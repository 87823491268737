<template>
  <b-modal
    id="fill-missing-mentor-fields-modal"
    ref="fill-missing-mentor-fields-modal"
    centered
    size="lg"
    :title="$t('You will need to input the profile fields below in the language you just added.')"
    title-class="font-weight-bolder"
    :ok-title="$t('Submit')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="missingMentorFieldsModalSubmitted"
    @cancel="missingMentorFieldsModalCancelled"
    @close="missingMentorFieldsModalClosed"
  >
    <personal-details
      ref="personal-details"
      :email-pre-check="false"
      show-mentor-fields
      show-only-multilanguage-fields
      :initial-locales="[newLocale]"
      force-flags
    />
    <professional-details
      ref="professional-details"
      show-mentor-fields
      show-only-multilanguage-fields
      :initial-locales="[newLocale]"
      force-flags
    />
    <mentor-profile
      ref="mentor-profile"
      show-only-multilanguage-fields
      :initial-locales="[newLocale]"
      force-flags
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import MentorProfile from '@mentoring-platform/views/components/blocks/MentorProfile.vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'

export default {
  components: {
    BModal,
    MentorProfile,
    PersonalDetails,
    ProfessionalDetails
  },
  data() {
    return {
      mentor: null,
      newLocale: null
    }
  },
  methods: {
    show(mentor, newLocale) {
      this.mentor = mentor
      this.newLocale = newLocale
      this.$refs['fill-missing-mentor-fields-modal'].show()
    },
    missingMentorFieldsModalSubmitted() {
      Promise.all([
        this.$refs['personal-details'].isValid(),
        this.$refs['professional-details'].isValid(),
        this.$refs['mentor-profile'].isValid()
      ])
        .then(values => {
          const isValid = !values.includes(false)
          if (isValid) {
            const {
              name, surname, professional_title, mentor_description
            } = {
              ...this.$refs['personal-details'].collectData(),
              ...this.$refs['professional-details'].collectData(),
              ...this.$refs['mentor-profile'].collectData()
            }
            /* eslint-disable camelcase */
            const payload = {
              name: { ...name, ...this.mentor.translatable_fields.name },
              surname: { ...surname, ...this.mentor.translatable_fields.surname },
              professional_title: { ...professional_title, ...this.mentor.translatable_fields.professional_title },
              mentor_description: { ...mentor_description, ...this.mentor.translatable_fields.description },
              mentor_id: this.mentor.id,
              selected_languages: this.mentor.supported_languages.map(language => language) // to clone the array
            }
            payload.selected_languages.push(this.newLocale)

            this.$store.dispatch('user/mentorEdit', payload)
              .then(() => {
                this.showSuccess(this.$t('Data successfully saved'))
                this.$bus.$emit('new-mentor-language-successfully-added')
                this.$refs['fill-missing-mentor-fields-modal'].hide()
              })
              .catch(() => {
                this.showError(this.$t('An error occured, please try again later'))
              })
          }
        })
    },
    missingMentorFieldsModalCancelled() {
      this.$emit('adding-new-locale-cancelled', this.newLocale)
    },
    missingMentorFieldsModalClosed() {
      this.$emit('adding-new-locale-cancelled', this.newLocale)
    },
    showSuccess(message) {
      this.$root.$bvToast.toast(message, {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError() {
      this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  },
}
</script>

<style scoped>

</style>
