<template>
  <b-input-group>
    <b-form-input
      v-model="input"
      :size="size"
    />
    <b-input-group-append v-if="appendFlag">
      <b-input-group-text>
        {{ selectedLocale.name }}
      </b-input-group-text>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BFormInput,
  // BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText
} from 'bootstrap-vue'
import { useLocales } from '@mentoring-platform/composables'

export default {
  components: {
    BFormInput,
    // BImg,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText
  },
  props: {
    value: {
      default: null,
      required: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    locale: {
      type: String,
      default: 'gr'
    },
    appendFlag: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    selectedLocale() {
      return this.locales.find(locale => locale.locale === this.locale)
    }
  },
  setup() {
    const { locales } = useLocales()

    return {
      locales,
    }
  }
}
</script>
