<template>
  <div>
    <validation-observer ref="personalDetailsObserver">
      <b-row>
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-name">
              {{ $t('Name') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="name[locale]"
                rules="required|min:2|max:50"
                mode="lazy"
              >
                <localized-input
                  :id="'i-name' + index"
                  v-model="name[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1 || forceFlags"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-surname">
              {{ $t('Surname') }} <required-field />
            </label>
            <div
              v-for="(locale, index) in selectedLocales"
              :key="index"
              :class="index < selectedLocales.length - 1 ? 'mb-1' : ''"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="surname[locale]"
                rules="required|min:3|max:50"
                mode="lazy"
              >
                <localized-input
                  :id="'i-surname' + index"
                  v-model="surname[locale]"
                  size="lg"
                  :locale="locale"
                  :append-flag="selectedLocales.length > 1 || forceFlags"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col xs="12" sm="6">
          <b-form-group :description="$t('This email will be used for sign in')">
            <label for="i-email">
              {{ $t('ContactEmail') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email|min:3|max:255"
              mode="lazy"
            >
              <b-form-input
                id="i-email"
                v-model="email"
                type="email"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-phone">
              {{ $t('Phone') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              rules="required"
              mode="lazy"
            >
              <b-form-input
                id="i-phone"
                v-model="phone"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-country">
              {{ $t('Country') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="country"
              rules="required"
              mode="lazy"
            >
              <b-form-select
                id="i-country"
                v-model="country"
                :options="countryOptions"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="6">
          <b-form-group v-if="countyVisible">
            <label for="i-county">
              {{ $t('County') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="county"
              rules="required"
              mode="lazy"
            >
              <b-form-select
                id="i-county"
                v-model="county"
                :options="countyOptions"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!showOnlyMultilanguageFields">
        <b-col xs="12" sm="6">
          <b-form-group>
            <label for="i-birthdate">
              {{ $t('Birthdate') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="birthdate"
              rules="required|date_format:dd-MM-yyyy"
              mode="lazy"
            >
              <cleave
                id="date"
                v-model="birthdate"
                class="form-control cleave-datepicker"
                :raw="false"
                :options="cleaveOptions.date"
                :placeholder="$t('DD-MM-YYYY')"
              />
              <!-- <b-form-input id="i-birthdate" placeholder="ΗΗ/ΜΜ/ΕΕΕΕ" v-model="birthdate" /> -->
              <!--<b-form-datepicker
                id="datepicker-full-width"
                v-model="birthdate"
                menu-class="w-100"
                calendar-width="100%"
                placeholder="ΗΗ/ΜΜ/ΕΕΕΕ"
                hide-header
                :show-decade-nav="true"
              />-->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <email-already-exists-modal ref="email-already-exists-modal" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormText
} from 'bootstrap-vue'
import { required, email, min, max, date_format } from '@mentoring-platform-validations'
import EmailAlreadyExistsModal from '@mentoring-platform/views/components/blocks/EmailAlreadyExistsModal.vue'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'
import { useEmailCheck, useCountryRepository, useCountyRepository } from '@mentoring-platform/composables'
import { formatGreekDate } from '@mentoring-platform/utils/filters'
import LocalizedInput from '@mentoring-platform/views/components/blocks/LocalizedInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormText,
    Cleave,
    EmailAlreadyExistsModal,
    LocalizedInput,
    RequiredField,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      name: {
        gr: null,
        en: null
      },
      surname: {
        gr: null,
        en: null
      },
      email: null,
      phone: null,
      county: null,
      country: null,
      birthdate: null,
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
        },
      },
      selectedLocales: []
    }
  },
  props: {
    showMentorFields: {
      type: Boolean,
      default: false
    },
    showOnlyMultilanguageFields: {
      type: Boolean,
      default: false
    },
    forceFlags: {
      type: Boolean,
      default: false
    },
    mentor: {
      type: Object
    },
    mentee: {
      type: Object
    },
    emailPreCheck: {
      type: Boolean,
      default: true
    },
    initialLocales: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    countyVisible() {
      if(this.country != this.countryIdOfGreece) {
        this.county = null
        return false
      }
      return true
    }
  },
  setup() {
    const { canBeUsed } = useEmailCheck()
    const { getCountries, countryOptions, countryIdOfGreece } = useCountryRepository()
    const { getCounties, countyOptions } = useCountyRepository()
    getCounties()
    getCountries()

    return {
      canBeUsed,
      countryOptions,
      countryIdOfGreece,
      countyOptions
    }
  },
  created() {
    let user = null
    if (this.mentor) {
      this.setLocales(this.mentor.supported_languages)
      this.name = this.mentor.translatable_fields.name
      this.surname = this.mentor.translatable_fields.surname
      user = this.mentor.user
    } else if (this.mentee) {
      this.setLocales(['gr'])
      this.name.gr = this.mentee.user.name
      this.surname.gr = this.mentee.user.surname
      user = this.mentee.user
    }

    if (user) {
      this.email = user.email
      this.phone = user.phone
      this.country = user.country_id
      this.county = user.county_id
      this.birthdate = formatGreekDate(user.birthdate, { month: '2-digit', year: 'numeric', day: '2-digit' })
    } else {
      this.country = this.countryIdOfGreece
      if (!this.showMentorFields) { // mentee registration
        this.setLocales(['gr'])
      } else if (this.initialLocales.length) {
        this.setLocales(this.initialLocales)
      }
    }
  },
  beforeMount() {
    this.$bus.$on('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$on('mentor-locales-changed', this.setLocales)
  },
  beforeDestroy() {
    this.$bus.$off('mentor-registration-locales-filled', this.setLocales)
    this.$bus.$off('mentor-locales-changed', this.setLocales)
  },
  methods: {
    async isValid() {
      let emailCanBeUsed = true
      if(this.emailPreCheck) {
        if(this.$refs.personalDetailsObserver.fields.email.valid) {
          /**
           * We could create a custom validation rule for vee-validate but we want to avoid hittng backend too many times. e.g. if mode is set to aggresive.
           * A solution to this is to create a rule that uses debouncing.
           */
          emailCanBeUsed = await this.canBeUsed(this.email)
          if(!emailCanBeUsed) {
            this.$refs['email-already-exists-modal'].show()
          }
        }
      }
      return this.$refs.personalDetailsObserver.validate().then(success => success && emailCanBeUsed)
    },
    collectData() {
      let name = {}
      let surname = {}
      if (this.showMentorFields) {
        this.selectedLocales.forEach(language => {
          name[language] = this.name[language]
          surname[language] = this.surname[language]
        })
      } else {
        name = { gr: this.name.gr }
        surname = { gr: this.surname.gr }
      }

      return {
        name,
        surname,
        email: this.email,
        phone: this.phone,
        country_id: this.country,
        county_id: this.county,
        birthdate: this.birthdate,
      }
    },
    setLocales(locales) {
      this.selectedLocales = locales
    }
  },
}
</script>

<style scoped>

</style>
